import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import download from 'downloadjs';
import { withPrefix } from 'gatsby';

// PDF parameters.
// Dimensions of a standard A4 sheet (in mm).
const a4dimensions = [210, 297];
// Default margin (in mm).
const pdfDefaultMargin = 3;
// Height of the watermark on full-page PDF exports (in mm).
// Note: ignored if the PDF export is not in full-page mode.
// Also ignored for PNG exports.
const pdfWatermarkPrintHeight = 8;

/**
 * Returns a filename with its extension.
 * Computes a default file name if no base name is provided.
 * @param {string|null} fileBaseName File name without extension.
 * @param {string|null} extension File extension. Example: 'png'.
 * @return {string} The filename to use, including extension.
 */
const getFileName = (fileBaseName, extension) => {
  let finalBaseName = fileBaseName;
  if (typeof fileBaseName !== 'string') {
    const date = new Date();
    finalBaseName = `export_lvdlr_${date.toLocaleDateString()}`;
  }

  if (typeof fileBaseName !== 'string') {
    return finalBaseName;
  }

  return `${finalBaseName}.${extension}`;
};

/**
 * Converts given DOM Element to a 2D canvas image.
 * @param {Element} targetElement Element to convert to image.
 * @param {string|null} ignoreClassString Elements having a CSS class that
 *                      includes this string fragment will get ignored.
 * @param {number} scale The rendering scale to apply.
 * @param {function|null} onClone The 'onclone' function to pass to html2canvas.
 * @return {Promise<HTMLCanvasElement>} The generated canvas, once ready.
 */
const htmlElementToCanvas = async (
  targetElement,
  ignoreClassString = null,
  scale = 2,
  onClone = null,
) => html2canvas(targetElement, {
  useCORS: true,
  allowTaint: true,
  scale,
  backgroundColor: null,
  removeContainer: true,
  logging: false,
  scrollX: -window.scrollX,
  scrollY: -window.scrollY,
  ignoreElements: ({ className: classes }) => typeof classes === 'string' && classes.includes(ignoreClassString),
  onclone: typeof onClone === 'function' ? onClone : null,
});
/**
 * Async function creating an image element, but returning it only
 * when the picture got actually loaded so the element has a size.
 * @see https://stackoverflow.com/a/66180709/2273543
 * @param {String} src Source path to the picture.
 * @return {Promise<HTMLElement>}
 */
const createAndLoadImageElement = src =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });

/**
 * Prepares a list of rendered canvas elements with their target positions and
 * sizes ready to be assembled by any subsequent process (to create a composed
 * picture, or PDF, ...).
 * @param {Object[]} elementConfigurations List of elements to prepare.
 *                   Expected structure:
 *                   [
 *                     {
 *                       // A query selector targeting the DOM element to
 *                       // render.
 *                       targetQuerySelector: string,
 *                       // A string containing a class name or a fragment of
 *                       // class name. All children elements that contain this
 *                       // in their class names will get excluded.
 *                       // Optional.
 *                       ignoreClassString: string,
 *                       // The rendering scale to apply.
 *                       // Optional, defaults to 1.
 *                       scale: number,
 *                       // Callback passed to html2canvas before rendering this
 *                       // element. Can be used to modify it without affecting
 *                       // the original in the source document.
 *                       // Optional.
 *                       onClone: function,
 *                     },
 *                     ...
 *                   ]
 * @return {Promise<Object>} The list of rendered canvas and some useful pieces of
 *         meta info. Structure:
 *         {
 *           // The total width of the virtual global canvas
 *           // containing all elements (for a scale of 1).
 *           width: number,
 *           // Same thing, for the total height (for a scale of 1).
 *           height: number,
 *           // Maximum scale of rendered canvases.
 *           maxScale: number,
 *           // List of elements contained in the global virtual canvas.
 *           elements: [
 *             {
 *               // The rendered canvas element.
 *               canvas: HTMLCanvasElement,
 *               // The X position of this element for a scale of 1.
 *               x: number,
 *               // The Y position of this element for a scale of 1.
 *               y: number,
 *               // The width of this element for a scale of 1.
 *               width: number,
 *               // The height of this element for a scale of 1.
 *               height: number,
 *               // The scale at which this canvas was rendered.
 *               scale: number,
 *             },
 *             ...
 *           ],
 *         }
 */
const prepareElementsForComposition = async (elementConfigurations = []) => {
  let minPosX = null;
  let minPosY = null;
  let maxPosX = null;
  let maxPosY = null;
  let maxScale = 0;
  const canvasesToRenderPromises = [];
  const elementMetas = [];
  elementConfigurations.forEach(elementConfiguration => {
    const element = document.querySelector(elementConfiguration.targetQuerySelector);
    // Skip not found elements.
    if (element === null) {
      return;
    }

    const elementRect = element.getBoundingClientRect();

    // Skip elements with no size.
    if (elementRect.width === 0 || elementRect.height === 0) {
      return;
    }

    if (minPosX === null || minPosX > elementRect.x) {
      minPosX = elementRect.x;
    }
    if (minPosY === null || minPosY > elementRect.y) {
      minPosY = elementRect.y;
    }
    if (maxPosX === null || maxPosX < elementRect.right) {
      maxPosX = elementRect.right;
    }
    if (maxPosY === null || maxPosY < elementRect.bottom) {
      maxPosY = elementRect.bottom;
    }

    const currentScale = elementConfiguration.scale ?? 1;

    // Add the scale to meta info.
    elementRect.scale = currentScale;
    if (maxScale < currentScale) {
      maxScale = currentScale;
    }

    elementMetas.push(elementRect);

    canvasesToRenderPromises.push(htmlElementToCanvas(
      element,
      elementConfiguration.ignoreClassString ?? null,
      currentScale,
      clonedDocument => {
        const clonedElement = clonedDocument.querySelector(
          elementConfiguration.targetQuerySelector,
        );
        if (clonedElement !== null) {
          if (typeof elementConfiguration.onClone === 'function') {
            elementConfiguration.onClone(clonedDocument);
          }

          // La version rc7 de html2canvas, ajoute des styles sur les éléments
          // SVG exportés qui posent soucis, il faut les retirer.
          const exportedSvgs = clonedDocument.querySelectorAll('svg');
          exportedSvgs.forEach(node => {
            if (node.style) {
              node.removeAttribute('style');
            }
          });
        }
      },
    ));
  });

  const elementCanvases = await Promise.all(canvasesToRenderPromises);

  // In case no elements could be rendered, set all properties to zero.
  if (elementCanvases.length === 0) {
    minPosX = 0;
    minPosY = 0;
    maxPosX = 0;
    maxPosY = 0;
    maxScale = 0;
  }

  const result = {
    width: maxPosX - minPosX,
    height: maxPosY - minPosY,
    maxScale,
    elements: [],
  };
  elementCanvases.forEach((elementCanvas, i) => {
    const elementMeta = elementMetas[i];

    result.elements.push({
      canvas: elementCanvas,
      x: elementMeta.x - minPosX,
      y: elementMeta.y - minPosY,
      width: elementMeta.width,
      height: elementMeta.height,
      scale: elementMeta.scale,
    });
  });

  return result;
};

/**
 * Returns a rendered canvas containing the watermark to include in exported
 * images/PDFs.
 * @param {number} scale The scale to apply for rendering. Default to 1.
 * @return {Promise<HTMLCanvasElement>}
 */
const getWaterMarkCanvas = async (scale = 1) => {
  const watermarkElement = document.createElement('span');
  watermarkElement.id = 'watermark';
  watermarkElement.style.position = 'absolute';
  watermarkElement.style.padding = '2px 5px';
  watermarkElement.style.top = '0';
  watermarkElement.style.left = '0';
  watermarkElement.style.visibility = 'hidden';
  watermarkElement.style.backgroundColor = 'rgba(255, 255, 255, 0.5)';
  watermarkElement.style.fontSize = '10px';
  watermarkElement.style.zIndex = '99999';
  watermarkElement.textContent = 'La Vie De La Rivière ';
  const watermarkLogo = await createAndLoadImageElement(withPrefix('/watermark-logo.png')).catch(() => {
    // In case something goes wrong with the picture, just do nothing, it will
    // simply be omitted from the returned canvas.
  });
  if (watermarkLogo) {
    watermarkLogo.style.maxWidth = '20px';
    watermarkLogo.style.maxHeight = '20px';
    watermarkLogo.style.verticalAlign = 'middle';
    watermarkElement.appendChild(watermarkLogo);
  }
  document.body.appendChild(watermarkElement);
  const watermarkElementCanvas = await htmlElementToCanvas(
    watermarkElement,
    null,
    scale,
    clonedDocument => {
      const watermark = clonedDocument.getElementById('watermark');
      if (watermark) {
        watermark.style.visibility = 'visible';
      }
    },
  );
  document.body.removeChild(watermarkElement);

  return watermarkElementCanvas;
};

/**
 * Exports given set of elements to a PNG file.
 * @param {Object[]} elementConfigurations List of elements to render.
 *                   Expected structure: See what
 *                   prepareElementsForComposition() expects.
 * @param {string|null} fileBaseName Base of the filename for download.
 * @return {Promise<void>}
 */
const exportPng = async (elementConfigurations, fileBaseName = null) => {
  const compositionConfiguration = await prepareElementsForComposition(elementConfigurations);

  // If no element could be rendered, cancel.
  if (compositionConfiguration.elements.length === 0) {
    return;
  }

  const globalScale = compositionConfiguration.maxScale;
  const watermarkElementCanvas = await getWaterMarkCanvas(globalScale);
  // Prepare final composed canvas.
  const finalCanvas = document.createElement('canvas');
  finalCanvas.width = compositionConfiguration.width * globalScale;
  finalCanvas.height = compositionConfiguration.height * globalScale
    + watermarkElementCanvas.height;
  const finalCanvasContext = finalCanvas.getContext('2d');
  // Make sure the background is white.
  finalCanvasContext.fillStyle = '#ffffff';
  finalCanvasContext.fillRect(0, 0, finalCanvas.width, finalCanvas.height);
  // Loop over elements to merge them.
  compositionConfiguration.elements.forEach(elementConfiguration => {
    // If current canvas is empty, ignore it as otherwise
    // CanvasRenderingContext2D.drawImage() fails with INDEX_SIZE_ERR.
    if (elementConfiguration.canvas.width === 0 || elementConfiguration.canvas.height === 0) {
      return;
    }

    finalCanvasContext.drawImage(
      elementConfiguration.canvas,
      elementConfiguration.x * globalScale,
      elementConfiguration.y * globalScale,
      elementConfiguration.width * globalScale,
      elementConfiguration.height * globalScale,
    );
  });

  // Add a watermark.
  finalCanvasContext.drawImage(
    watermarkElementCanvas,
    finalCanvas.width - watermarkElementCanvas.width,
    finalCanvas.height - watermarkElementCanvas.height,
    watermarkElementCanvas.width,
    watermarkElementCanvas.height,
  );

  finalCanvas.toBlob(canvasAsBlob => {
    download(canvasAsBlob, getFileName(fileBaseName, 'png'), 'image/png');
  });
};

const getJsPDFConfig = (canvasWidth, canvasHeight, a4PDFPage = false) => {
  let orientation = 'portrait';
  let isPortrait = true;
  if (canvasWidth > canvasHeight) {
    orientation = 'landscape';
    isPortrait = false;
  }
  let configuration = {
    orientation,
    unit: 'mm',
    format: isPortrait ? a4dimensions : [a4dimensions[1], a4dimensions[0]],
  };
  if (!a4PDFPage) {
    configuration = {
      orientation,
      unit: 'px',
      format: [canvasWidth, canvasHeight],
      hotfixes: ['px_scaling'],
    };
  }

  return configuration;
};

/**
 * Exports given set of elements to a PDF file.
 * @param {Object[]} elementConfigurations List of elements to render.
 *                   Expected structure: See what
 *                   prepareElementsForComposition() expects.
 * @param {boolean} a4PDFPage Whether the whole composition should
 *                  be displayed on a standard A4 page, in full page.
 * @param {string|null} fileBaseName Base of the filename for download.
 * @return {Promise<void>}
 */
const exportPdf = async (
  elementConfigurations,
  a4PDFPage = true,
  fileBaseName = null,
) => {
  const compositionConfiguration = await prepareElementsForComposition(elementConfigurations);

  // If no element could be rendered, cancel.
  if (compositionConfiguration.elements.length === 0) {
    return;
  }

  let margin = pdfDefaultMargin;
  if (!a4PDFPage) {
    margin = 0;
  }

  const configuration = getJsPDFConfig(
    compositionConfiguration.width,
    compositionConfiguration.height,
    a4PDFPage,
  );
  // eslint-disable-next-line new-cap
  const doc = new jsPDF(configuration);

  // Prepare watermark.
  const watermarkElementCanvas = await getWaterMarkCanvas(2);
  let waterMarkPrintWidth = watermarkElementCanvas.width / compositionConfiguration.maxScale;
  let waterMarkPrintHeight = watermarkElementCanvas.height / compositionConfiguration.maxScale;
  if (configuration.unit === 'mm') {
    waterMarkPrintWidth = (watermarkElementCanvas.width / watermarkElementCanvas.height)
      * pdfWatermarkPrintHeight;
    waterMarkPrintHeight = pdfWatermarkPrintHeight;
  }

  const [printAreaWidth, printAreaHeight] = [
    configuration.format[0] - (2 * margin),
    configuration.format[1] - (2 * margin) - waterMarkPrintHeight,
  ];

  const printScalingFactor = Math.min(
    printAreaWidth / compositionConfiguration.width,
    printAreaHeight / compositionConfiguration.height,
  );

  // Offset to center content if we are in full page mode.
  const xPrintOffset = (printAreaWidth - (compositionConfiguration.width * printScalingFactor))
    / 2 + margin;
  const yPrintOffset = (printAreaHeight - (compositionConfiguration.height * printScalingFactor))
    / 2 + margin;

  // Loop over elements to superimpose, and render them to canvases.
  compositionConfiguration.elements.forEach(elementConfiguration => {
    // If current canvas is empty, ignore it.
    if (elementConfiguration.canvas.width === 0 || elementConfiguration.canvas.height === 0) {
      return;
    }

    doc.addImage(
      elementConfiguration.canvas,
      'PNG',
      xPrintOffset + (elementConfiguration.x * printScalingFactor),
      yPrintOffset + (elementConfiguration.y * printScalingFactor),
      elementConfiguration.width * printScalingFactor,
      elementConfiguration.height * printScalingFactor,
      null,
      'FAST',
    );
  });

  // Add the watermark.
  doc.addImage(
    watermarkElementCanvas,
    'PNG',
    margin + printAreaWidth - waterMarkPrintWidth,
    margin + printAreaHeight,
    waterMarkPrintWidth,
    waterMarkPrintHeight,
    null,
    'FAST',
  );

  doc.save(getFileName(fileBaseName, 'pdf'));
};

/**
 * Exports given element to image or PDF.
 *
 * @param {string} format Format to export to. Possibles value: 'PDF' or 'PNG'.
 * @param {Object[]} elementConfigurations List of elements to render.
 *                   Expected structure: See what
 *                   prepareElementsForComposition() expects.
 * @param {boolean} a4PDFPage Whether the whole composition should
 *                  be displayed on a standard A4 page, in full page.
 *                  Applies to PDF format only.
 * @param {string|null} fileBaseName Base of the filename for download.
 * @return {Promise<void>}
 */
const exportFile = async (
  format,
  elementConfigurations,
  a4PDFPage = true,
  fileBaseName = null,
) => {
  if (format !== 'PDF') {
    await exportPng(elementConfigurations, fileBaseName);
  } else {
    await exportPdf(elementConfigurations, a4PDFPage, fileBaseName);
  }
};

export default exportFile;
