import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Backdrop, CircularProgress, Menu, MenuItem } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';

import exportFile from '../print';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const formats = ['PDF', 'PNG'];

const PrintButton = ({
  elementConfigurations = [],
  a4PDFPage = true,
  buttonProps = null,
  iconProps = null,
  fileBaseName = null,
}) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleClickExport = async format => {
    setOpen(true);
    await exportFile(
      format,
      elementConfigurations,
      a4PDFPage,
      fileBaseName,
    );
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = event => {
    const value = event.currentTarget.textContent;
    // if we click outside the menu, we don't want to trigger an export
    const format = formats.includes(value) ? value : null;
    if (format) {
      handleClickExport(format);
    }
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="small"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClickMenu}
        {...buttonProps}
      >
        <PrintIcon {...iconProps} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleCloseMenu}>{formats[0]}</MenuItem>
        <MenuItem onClick={handleCloseMenu}>{formats[1]}</MenuItem>
      </Menu>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default PrintButton;
